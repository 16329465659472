import { action, createModule, mutation } from 'vuex-class-component'
import UserApiService, {
  User,
  UserId,
  UserInfoResponse,
} from '~/src/services/UserApiService'

const VuexModule = createModule({
  namespaced: 'users',
  strict: false,
  target: 'nuxt',
})

const SELECTED_COMPANY_KEY = 'selectedCompany'

export class UserStore extends VuexModule {
  loggedIn: User | null = null
  loggedInId: UserId | null = null
  userInfo: UserInfoResponse | null = null
  selectedCompany: string | null = sessionStorage.getItem(SELECTED_COMPANY_KEY)

  @mutation
  setLoggedIn({ id, user }: { id: UserId | null; user: User | null }): void {
    this.loggedInId = id
    this.loggedIn = user
  }

  @mutation
  setUserInfo(userInfo: UserInfoResponse): void {
    this.userInfo = userInfo
  }

  @mutation
  setSelectedCompany(uuid: string | null): void {
    this.selectedCompany = uuid

    if (uuid === null) {
      sessionStorage.removeItem(SELECTED_COMPANY_KEY)
    } else {
      sessionStorage.setItem(SELECTED_COMPANY_KEY, uuid)
    }
  }

  @action
  async fetchLoggedIn({
    id,
    userApiService,
    ignoreExisting = false,
  }: {
    id: UserId | null
    userApiService: UserApiService
    ignoreExisting?: boolean
  }): Promise<void> {
    if (id === null) {
      this.setLoggedIn({ id: null, user: null })
      return
    }

    if (!ignoreExisting && this.loggedInId === id) {
      return
    }

    this.setLoggedIn({
      id,
      user: await userApiService.get(id),
    })

    const userInfo = await userApiService.info(id)
    this.setUserInfo(userInfo)
  }

  @action
  selectCompany(uuid: string): Promise<void> {
    this.setSelectedCompany(uuid)
    return Promise.resolve()
  }
}
