import Vue from 'vue'
import VueGTag from 'vue-gtag'
import type { Plugin } from '@nuxt/types'
import { useVxm } from '~/src/composables/dependency'

const plugin: Plugin = (ctx) => {
  const vxm = useVxm()
  const config = vxm.portalConfig.config

  Vue.use(
    VueGTag,
    {
      config: {
        id: config?.tracking?.googleAnalytics ?? '',
      },
      enabled: false,
    },
    ctx.app.router
  )
}

export default plugin
