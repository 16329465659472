
import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
} from '@vue/composition-api'
import { Color } from '~/types/DesignConfig'

export default defineComponent({
  props: {
    primaryColor: {
      type: Object as PropType<Color | null>,
      default: null,
    },
    secondaryColor: {
      type: Object as PropType<Color | null>,
      default: null,
    },
    loginLogoBgColor: {
      type: Object as PropType<Color | null>,
      default: null,
    },
  },
  setup(props) {
    const themeStyles: ComputedRef<Record<string, unknown>> = computed(() => ({
      ...(props.primaryColor && {
        '--p-color-primary-100': props.primaryColor?.toArray().join(','),
      }),
      ...(props.secondaryColor && {
        '--p-color-secondary-100': props.secondaryColor?.toArray().join(','),
      }),
      ...(props.loginLogoBgColor && {
        '--p-bg-color-logo-layout': `rgb(${props.loginLogoBgColor
          ?.toArray()
          .join(',')})`,
      }),
    }))

    return { themeStyles }
  },
})
