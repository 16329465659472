import { RawLocation } from 'vue-router'

type Optional<T, K extends keyof T> = Omit<T, K> & Partial<T>

export interface NavigationItem {
  route: RawLocation
  icon: string
  activeIcon: string
  label: string
  exact: boolean
  userHasPermission: boolean
  isExternal: boolean
  openInNewTab: boolean
}

export type NavigationItemConstructor = Optional<
  NavigationItem,
  'activeIcon' | 'exact' | 'isExternal' | 'userHasPermission' | 'openInNewTab'
>

export interface UseNavigation {
  makeItem: (navigationItem: NavigationItemConstructor) => NavigationItem
}

export default function useNavigation(): UseNavigation {
  function makeItem({
    route,
    icon,
    activeIcon = undefined,
    label,
    exact = false,
    isExternal = false,
    userHasPermission = undefined,
    openInNewTab = false,
  }: NavigationItemConstructor) {
    const item = {
      route,
      icon,
      activeIcon: activeIcon ?? icon,
      label,
      exact,
      userHasPermission: userHasPermission ?? true,
      isExternal,
      openInNewTab,
    }

    return item
  }

  return { makeItem }
}
