import { pathToRegexp } from 'path-to-regexp'
import { action, createModule, mutation } from 'vuex-class-component'
import type { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import { makeAssetUrl } from '~/src/composables/useAsset'
import { JobTitlesConfig } from '~/types/jobTitles'
import {
  DefaultPageConfig,
  IframePageConfig,
  ModulePageConfig,
  NavigationPageConfig,
  PortalConfig,
  PortalGeneratedPage,
  PortalPage,
  PortalPageType,
  Routed,
} from '~/types/portal-config'
import { setVersion } from '~/helpers/makeAssetUrl'

const VuexModule = createModule({
  namespaced: 'portalConfig',
  strict: false,
  target: 'nuxt',
})

type MergedConfig = PortalConfig &
  Required<Pick<PortalConfig, 'title' | 'fuchsbau' | 'registrationContact'>>

interface SetConfigPayload {
  config: MergedConfig
  jobTitles: JobTitlesConfig
}

interface Metadata {
  version: string
  // eslint-disable-next-line camelcase
  build_date?: string
}

function assertFetched(store: {
  fetched: boolean
  config: MergedConfig | null
}): asserts store is { fetched: true; config: MergedConfig } {
  if (!store.fetched) {
    throw new Error('PortalConfigStore: config not fetched')
  }
}

export class PortalConfigStore extends VuexModule {
  config: MergedConfig | null = null
  jobTitleConfig: JobTitlesConfig | null = null
  meta: Metadata | null = null
  fetched = false
  presetModules: ModulePageConfig[] = []

  @mutation
  SET_META(meta: Metadata): void {
    this.meta = meta
    setVersion(this.meta.build_date ?? this.meta.version)
  }

  @mutation
  SET_CONFIG({ config, jobTitles }: SetConfigPayload): void {
    this.config = { ...config }
    this.jobTitleConfig = { ...jobTitles }
  }

  @mutation
  SET_FETCHED(): void {
    this.fetched = true
  }

  @mutation
  SET_PRESET_MODULES(modules: ModulePageConfig[]): void {
    this.presetModules = modules
  }

  @action
  async fetchMeta(): Promise<void> {
    if (this.meta === null) {
      const response = await fetch('/meta.json')
      const meta = await response.json()

      this.SET_META(meta)
    }
  }

  @action
  async initialize(runtimeConfig: NuxtRuntimeConfig): Promise<void> {
    if (this.fetched) {
      return
    }

    const { assetsURL, modulePublicPath } = runtimeConfig

    const configPromise = Promise.all([
      (async (): Promise<PortalConfig> => {
        const response = await fetch(
          makeAssetUrl('/portal.config.json', assetsURL)
        )
        return await response.json()
      })(),
      (async (): Promise<JobTitlesConfig> => {
        const response = await fetch(
          makeAssetUrl('/job-titles.json', assetsURL)
        )
        return await response.json()
      })(),
    ])

    this.SET_PRESET_MODULES([
      {
        basePath: 'backoffice/usermanagement/m/',
        route: {
          path: '/backoffice/usermanagement/m/(.*)',
          name: 'management-module',
        },
        rootUrl: modulePublicPath,
        css: 'style.css',
        src: 'src/main.ts',
        format: 'esm',
        manifest: 'manifest.json',
      },
    ])

    const [config, jobTitles] = await configPromise

    // merge runtime config
    const mergedConfig: MergedConfig = {
      ...config,
      title: config.title ?? runtimeConfig.viewportName,
      fuchsbau: {
        id: config.fuchsbau?.id ?? runtimeConfig.fuchsbauId,
        name: config.fuchsbau?.name ?? runtimeConfig.fuchsbauName,
      },
      registrationContact:
        config.registrationContact ?? runtimeConfig.registration_info_contact,
    }

    this.SET_CONFIG({ config: mergedConfig, jobTitles })
    this.SET_FETCHED()
  }

  get pages(): Record<string, DefaultPageConfig> {
    return this.config?.pages ?? {}
  }

  get iframes(): IframePageConfig[] {
    return this.config?.iframes ?? []
  }

  get navigationPages(): NavigationPageConfig[] {
    return this.config?.navigationPages ?? []
  }

  get modules(): ModulePageConfig[] {
    return [...this.presetModules, ...(this.config?.modules ?? [])]
  }

  get regexMapping(): Record<string, RegExp> {
    const regExps: Record<string, RegExp> = {}

    function addRegExp(path: string) {
      regExps[path] = pathToRegexp(path)
    }

    function parseGeneratedPage(page: Routed) {
      addRegExp(page.route.path)
    }

    Object.keys(this.pages).forEach(addRegExp)
    this.iframes.forEach(parseGeneratedPage)
    this.navigationPages.forEach(parseGeneratedPage)
    this.modules.forEach(parseGeneratedPage)

    return regExps
  }

  get allPages(): Record<string, PortalPage> {
    const pages: Record<string, PortalPage> = {}

    function parsePermission(permission: number) {
      return Math.pow(2, permission)
    }

    function addGeneratedPage(page: PortalGeneratedPage) {
      const generatedPage = {
        ...page,
      }

      if (page.permission) {
        generatedPage.permission = parsePermission(page.permission)
      }

      pages[page.route.path] = generatedPage
    }

    Object.entries(this.pages).forEach(([path, page]) => {
      pages[path] = {
        permission: parsePermission(page.permission),
        title: page.title,
        type: PortalPageType.DEFAULT,
      }
    })

    this.iframes.forEach((iframe) =>
      addGeneratedPage({
        ...iframe,
        type: PortalPageType.IFRAME,
      })
    )
    this.navigationPages.forEach((navigationPage) =>
      addGeneratedPage({ ...navigationPage, type: PortalPageType.NAVIGATION })
    )
    this.modules.forEach((module) =>
      addGeneratedPage({ ...module, type: PortalPageType.MODULE })
    )

    return pages
  }

  get jobTitlesEnabled(): boolean {
    return this.jobTitleConfig?.enabled ?? false
  }

  get jobTitles(): JobTitlesConfig['items'] {
    return this.jobTitleConfig?.enabled ? this.jobTitleConfig?.items ?? [] : []
  }

  get title(): string {
    assertFetched(this)
    return this.config.title
  }

  get fuchsbauName(): string {
    assertFetched(this)
    return this.config.fuchsbau.name
  }

  get fuchsbauId(): string {
    assertFetched(this)
    return this.config.fuchsbau.id
  }

  get registrationContact(): string {
    assertFetched(this)
    return this.config.registrationContact
  }
}
