import { render, staticRenderFns } from "./PDeleteConfirmModal.vue?vue&type=template&id=035b5bfc&"
import script from "./PDeleteConfirmModal.vue?vue&type=script&lang=ts&"
export * from "./PDeleteConfirmModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PHeadline: require('/app/components/PHeadline.vue').default,PModal: require('/app/components/PModal.vue').default})
