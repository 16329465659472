import type { Plugin } from '@nuxt/types'
import type { Auth } from '@nuxtjs/auth-next'
import type { Store } from 'vuex'

function setSelectedCompanyFromUser(store: Store<unknown>, $auth: Auth) {
  return store.dispatch('users/selectCompany', $auth.user?.company ?? null)
}

const companySelect: Plugin = ({ $auth, store }) => {
  if (store.state.users.selectedCompany === null) {
    setSelectedCompanyFromUser(store, $auth)
  }

  $auth.$storage.watchState('loggedIn', (newLoggedIn, old) => {
    if (newLoggedIn === false) {
      store.dispatch('users/selectCompany', null)
    } else {
      setSelectedCompanyFromUser(store, $auth)
    }
  })
}

export default companySelect
