var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-main-navigation",class:{
    'p-main-navigation--extended': _vm.isExtended,
    'p-main-navigation--closed': !_vm.isMinLg && !_vm.isOpen,
  },attrs:{"data-testid":"navigation"}},[_c('nav',{staticClass:"p-main-navigation__nav"},[_vm._l((_vm.items),function(item){return [(!item.isExternal)?_c('nuxt-link',{key:item.route,attrs:{"to":item.route,"custom":"","exact":item.exact},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var href = ref.href;
  var isActive = ref.isActive;
  var navigate = ref.navigate;
return [_c('p-main-navigation-link',{attrs:{"href":href,"is-active":isActive,"icon":isActive ? item.activeIcon : item.icon,"label":item.label,"data-testid":item.icon},nativeOn:{"click":function($event){return navigate.apply(null, arguments)}}})]}}],null,true)}):_c('p-main-navigation-link',{key:item.route,attrs:{"href":item.route,"icon":item.icon,"label":item.label,"target":item.openInNewTab ? '_blank' : undefined,"data-testid":item.icon}})]})],2),_vm._v(" "),(!_vm.isMaxMd)?_c('div',{staticClass:"p-main-navigation__toggle",attrs:{"data-testid":"toggle"},on:{"click":_vm.toggle}},[_c('p-icon',{staticClass:"p-main-navigation__toggle-icon",attrs:{"name":"chevron-double-right","width":"30"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }