
import { computed, ref, Ref, useFetch, useRoute, useRouter, watch } from '@nuxtjs/composition-api';
import type { FlyoutItem } from '~/components/PFlyout.vue';
import { useAuth, useUserApiService, useVxm } from '~/src/composables/dependency';
import { useI18n } from '~/src/composables/useI18n';
import { fileIdToDataUrl } from '~/helpers/fileIdToDataUrl';
import type { Cluster } from '~/src/services/UserApiService';
import { useUserInfoQuery } from '~/src/queries/user';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    users,
    navigation
  } = useVxm();
  const auth = useAuth();
  const userApiService = useUserApiService();
  const avatarSrc: Ref<string | null> = ref(null);
  const route = useRoute();
  const router = useRouter();
  const i18n = useI18n();
  const isMainNavOpen = computed(() => navigation.isMainOpen);
  const loggedInId = computed(() => users.loggedInId ?? undefined);
  const userInfoQuery = useUserInfoQuery(loggedInId);
  const userInfo = userInfoQuery.data;
  const currentCompany = computed<Cluster>(() => {
    const cid = users.selectedCompany;
    let result = {
      id: '',
      name: '...'
    };
    if (cid !== null && userInfo.value !== undefined && userInfo.value.cluster.length > 0) {
      const found = userInfo.value.cluster.find(one => one.id === cid);
      if (found) {
        result = found;
      }
    }
    return result;
  });
  function makeCompanyItem(clusterItem: Cluster, partItem: Partial<FlyoutItem> = {}): FlyoutItem {
    return {
      ...partItem,
      icon: 'office-building',
      activeIcon: 'solid/office-building',
      label: clusterItem.name
    };
  }
  const companyItems = computed<FlyoutItem[]>(() => userInfo.value?.cluster.map(one => makeCompanyItem(one, {
    action: () => users.setSelectedCompany(one.id)
  })) ?? []);
  const flyoutItems = computed((): FlyoutItem[] => [makeCompanyItem(currentCompany.value, {
    submenu: companyItems.value
  }), {
    icon: 'user',
    activeIcon: 'solid/user',
    label: (i18n.t('common.profile') as string),
    to: '/user/profile'
  }, {
    icon: 'book-open',
    label: (i18n.t('common.legal.tos') as string),
    href: router.resolve({
      name: 'tos'
    }).href,
    target: '_blank'
  }, {
    icon: 'shield-check',
    label: (i18n.t('common.legal.privacy') as string),
    href: router.resolve({
      name: 'privacy'
    }).href,
    target: '_blank'
  }, {
    icon: 'document-text',
    label: (i18n.t('common.legal.imprint') as string),
    href: router.resolve({
      name: 'imprint'
    }).href,
    target: '_blank'
  }, {
    icon: 'logout',
    label: (i18n.t('common.logout') as string),
    action: () => auth.logout()
  }]);
  const isFlyoutActive = computed(() => flyoutItems.value.find(item => item.to === route.value.path));
  useFetch(async () => {
    await Promise.all([users.fetchLoggedIn({
      id: (auth.user?.id as string | null),
      userApiService
    })]);
  });
  watch(() => users.loggedIn, async loggedIn => {
    if (loggedIn === null || loggedIn.profileImage === null) {
      avatarSrc.value = '';
    } else {
      avatarSrc.value = await fileIdToDataUrl(loggedIn.profileImage);
    }
  }, {
    immediate: true
  });
  const menuIcon = computed(() => !isMainNavOpen.value ? 'menu' : 'x');
  function toggleMainNavigation() {
    navigation.setIsMainOpen(!isMainNavOpen.value);
  }
  return {
    avatarSrc,
    flyoutItems,
    isFlyoutActive,
    menuIcon,
    toggleMainNavigation
  };
};
export default __sfc_main;
