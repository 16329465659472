import axios, { AxiosError, AxiosInstance } from 'axios'
import { inject, injectable } from 'inversify'
import ShallowDiffer, { ShallowDiffResult } from './types/ShallowDiffer'
import { SYMBOLS } from '~/src/dependency_injection/Symbols'
import { ConstraintsViolatedError } from '~/src/models/ConstraintsViolatedError'
import { ListResponse } from '~/src/services/types/ListResponse'
import { OrderBy } from '~/types/portal'
import serializeParams from '~/helpers/serializeParams'

export type UserId = string

export class UserNotFoundError extends Error {}

export type UserAdditionalPropertyValue = string | Record<string, unknown>

export type UserAdditionalProperties = Record<
  string,
  UserAdditionalPropertyValue | Array<UserAdditionalPropertyValue>
>

export interface BaseUser {
  firstName: string | null
  lastName: string | null
  title: string | null
  phone: string | null
  mobilePhone: string | null
  department: string | null
  jobTitle: string | null
  birthDate: string | null
  preferredLanguage: string | null
  additionalProperties: UserAdditionalProperties | null
}

export interface User extends BaseUser {
  email: string
  profileImage: string | null
  additionalProperties: UserAdditionalProperties
}

interface Identifiable extends User {
  id: UserId
}

export interface UserRole {
  company: string
  role: string
}

export type UserWithId = User & Identifiable

export interface UserRead extends User, Identifiable {
  company: Readonly<string>
  companyId: Readonly<string>
  updated: Readonly<string>
  created: Readonly<string>
  lastLogin: Readonly<string>
  roles: UserRole[]
}

export interface UserPost extends User {
  currentPassword: string
  newPassword: string
}

export interface Cluster {
  id: string
  name: string
}

export interface UserInfoResponse {
  cluster: Array<Cluster>
}

export type GetUserResponse = ListResponse<UserRead[]>

type UpdateRoles = {
  roles: UserRole[]
}

export type UserSortingKey = 'firstName' | 'lastName' | 'email' | 'company'
export type UserOrder = OrderBy<UserSortingKey>
export type UserSortingKeys = ['lastName', 'firstName', 'email', 'company']

type UserDiff = User | UserPost

export interface ListParameters {
  page?: number
  order?: UserOrder
  company?: string[]
  role?: string[]
  search?: string
}

@injectable()
export default class UserApiService {
  private differ: ShallowDiffer<UserDiff>

  public constructor(
    @inject(SYMBOLS.Axios) private readonly axios: AxiosInstance,
    @inject(SYMBOLS.FuchsbauId) private readonly fuchsbauId: string
  ) {
    this.differ = new ShallowDiffer<UserDiff>()
  }

  public async update(
    id: Readonly<UserId>,
    userOrig: Readonly<UserRead>,
    userChanged: Readonly<UserPost>
  ): Promise<void> {
    let putData: Partial<UserPost> = userChanged

    if (
      !this.isStringSet(putData, 'currentPassword') ||
      !this.isStringSet(putData, 'newPassword')
    ) {
      delete putData.currentPassword
      delete putData.newPassword
    }

    let k: keyof typeof putData

    for (k in putData) {
      if (
        k === 'additionalProperties' ||
        k === 'email' ||
        k === 'currentPassword' ||
        k === 'newPassword'
      ) {
        continue
      }

      const value = putData[k]
      if (typeof value === 'string' && value.trim().length === 0) {
        putData[k] = null
      }
    }

    putData = this.diff(userOrig, putData as UserPost) ?? {}

    try {
      await this.axios.put('/user/' + id, putData)
      return
    } catch (e) {
      const status = (e as AxiosError).response?.status
      if (status === 404) {
        throw new UserNotFoundError()
      } else if (status === 422) {
        throw new ConstraintsViolatedError((e as AxiosError).response?.data)
      }

      throw e
    }
  }

  public async updateRoles(
    id: Readonly<UserId>,
    roles: UserRole[]
  ): Promise<void> {
    const putData: UpdateRoles = { roles }
    try {
      await this.axios.put('/user/' + id + '/roles', putData)
      return
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const status = e.response?.status
        if (status === 404) {
          throw new UserNotFoundError()
        }
      }

      throw e
    }
  }

  public async get(id: Readonly<UserId>): Promise<UserRead> {
    try {
      const response = await this.axios.get<UserRead>('/user/' + id)
      return response.data
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        const status = e.response?.status
        if (status === 404) {
          throw new UserNotFoundError()
        }
      }

      throw e
    }
  }

  public delete(id: Readonly<UserId>): Promise<void> {
    try {
      return this.axios.post('/user/' + id + '/soft-delete')
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        const status = e.response?.status

        if (status === 404) {
          throw new UserNotFoundError()
        }
      }

      throw e
    }
  }

  public async list(params: ListParameters = {}): Promise<GetUserResponse> {
    const response = await this.axios.get<GetUserResponse>('/user', {
      params,
      paramsSerializer: serializeParams,
    })

    return response.data
  }

  public async search(
    search: string,
    companyId?: string
  ): Promise<GetUserResponse> {
    const params: Record<string, string | string[]> = { search }

    if (companyId !== undefined) {
      const companies: [string] = [companyId]

      if (this.fuchsbauId !== companyId) {
        companies.push(this.fuchsbauId)
      }

      params.company = companies
    }

    const response = await this.axios.get<GetUserResponse>('/user', {
      params,
    })

    return response.data
  }

  private diff(
    userOrig: Readonly<User>,
    userChanged: Readonly<UserPost>
  ): ShallowDiffResult<UserDiff> {
    return this.differ.diff(userChanged, userOrig)
  }

  private isStringSet<T>(obj: T, key: keyof T): boolean {
    const value = obj[key]
    return typeof value === 'string' && value.trim().length > 0
  }

  public async info(userId: string): Promise<UserInfoResponse> {
    const response = await this.axios.get<UserInfoResponse>(
      `/user/${userId}/info`
    )

    return response.data
  }
}
