import { optIn, optOut } from 'vue-gtag'
import type { Plugin } from '@nuxt/types'
import { useVxm } from '~/src/composables/dependency'

const plugin: Plugin = () => {
  const vxm = useVxm()
  const config = vxm.portalConfig.config

  function handleCmpInit(..._: unknown[]) {
    const cmpData = __cmp('getCMPData')

    if (
      typeof config?.tracking?.googleAnalytics === 'string' &&
      cmpData?.purposeConsents?.c54
    ) {
      optIn()
    } else {
      optOut()
    }
  }

  __cmp('addEventListener', ['consent', handleCmpInit, false], null)
  handleCmpInit()
}

export default plugin
