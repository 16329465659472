import { Plugin } from '@nuxt/types'
import { onGlobalSetup, useMeta } from '@nuxtjs/composition-api'
import { useI18n } from '~/src/composables/useI18n'

const plugin: Plugin = (ctx) => {
  onGlobalSetup(() => {
    const meta = useMeta()
    const i18n = useI18n()

    meta.link.value.push({
      rel: 'preload',
      as: 'fetch',
      type: 'application/json',
      crossOrigin: 'anonymous',
      href: `${ctx.$config.apiUrl}/configuration`,
    })

    const title = i18n.t('common.title') as string

    meta.meta.value.push({
      hid: 'og:title',
      property: 'og:title',
      content: title,
    })

    meta.meta.value.push({
      hid: 'og:description',
      property: 'og:description',
      content: ctx.$config.fuchsbauName,
    })

    meta.meta.value.push({
      hid: 'description',
      name: 'description',
      content: ctx.$config.fuchsbauName,
    })
  })
}

export default plugin
