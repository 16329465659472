
import {
  computed,
  defineComponent,
  Ref,
  ref,
  useContext,
  useRoute,
  watch,
  watchEffect,
} from '@nuxtjs/composition-api'

import useNavigation, { NavigationItem } from '~/src/composables/useNavigation'
import { makeConfigNavigationItems } from '~/helpers/portalConfig'
import { useNavPermissionsService, useVxm } from '~/src/composables/dependency'
import {
  isMaxLg,
  isMaxMd,
  isMaxSm,
  isMinLg,
  isMinXl,
} from '~/helpers/breakpointProvider'

export default defineComponent({
  name: 'PMainNavigation',
  setup() {
    const {
      $config: { NavPermissionService },
    } = useContext()

    const { navigation } = useVxm()

    const { makeItem } = useNavigation()
    const items: Ref<NavigationItem[]> = ref([])
    const route = useRoute()
    const { name } = route.value
    const routeName = ref(name)

    const isOpen = computed(() => navigation.isMainOpen)
    const isExtended = computed({
      get: () => navigation.isMainExtended,
      set: (isExtended: boolean) => navigation.setIsMainExtended(isExtended),
    })

    const navPermissionService = useNavPermissionsService()

    function hasNavPermission(permission?: number) {
      if (permission === undefined) {
        return true
      }

      return navPermissionService
        .generateStaticNavPermission(permission)
        .isAllowed()
    }

    const staticItems = [
      {
        route: '/',
        icon: 'home',
        activeIcon: 'solid/home',
        label: 'navigation.dashboard',
        exact: true,
      },
      {
        route: '/backoffice/usermanagement',
        label: 'navigation.usermanagement_index',
        icon: 'users',
        activeIcon: 'solid/users',
        permission: 1,
      },
      {
        route: '/settings/ci',
        label: 'navigation.settings.ci',
        icon: 'pencil',
        activeIcon: 'solid/pencil',
        permission: 2,
      },
      {
        route: '/contacts',
        label: 'navigation.contacts',
        icon: 'chat-alt-2',
        activeIcon: 'solid/chat-alt-2',
        permission: 4,
      },
      {
        route: '/data-pools',
        label: 'navigation.data_pools',
        icon: 'database',
        activeIcon: 'solid/database',
        permission: 32,
      },
      {
        route: '/documents',
        label: 'navigation.documents',
        icon: 'document-duplicate',
        activeIcon: 'solid/document-duplicate',
        permission: 16,
      },
    ]

    for (const { permission, ...item } of staticItems) {
      items.value.push(
        makeItem({
          ...item,
          userHasPermission: hasNavPermission(permission),
        })
      )
    }

    items.value.push(...makeConfigNavigationItems(NavPermissionService))
    items.value = items.value.filter((item) => item.userHasPermission)

    const toggle = () => {
      isExtended.value = !isExtended.value
    }

    function close() {
      navigation.closeMain()
    }

    watch(
      [isMaxMd, isMinXl],
      ([isMaxMd, isMinXl]) => {
        isExtended.value = isMaxMd || isMinXl
      },
      { immediate: true }
    )

    watchEffect(() => {
      const { name } = route.value

      if (routeName.value !== name && isMaxLg.value) {
        close()
        routeName.value = name

        if (isMinLg.value) {
          isExtended.value = false
        }
      }

      if (isOpen.value && isMaxSm.value) {
        document.body.style.overflow = 'hidden'
        document.body.style.touchAction = 'none'
      } else {
        document.body.style.overflow = ''
        document.body.style.touchAction = ''
      }
    })

    return {
      isOpen,
      isExtended,
      isMinLg,
      isMaxMd,
      items,
      toggle,
    }
  },
})
