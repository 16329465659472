let version = ''

export function setVersion(newVersion: string): void {
  version = newVersion
}

export function appendVersionQuery(url: string): string {
  let newUrl: string = url

  if (version !== '') {
    newUrl += `?version=${version}`
  }

  return newUrl
}

export function makeAssetUrl(path: string, assetsUrl: string): string {
  return appendVersionQuery(assetsUrl + path)
}
