import { render, staticRenderFns } from "./BaseLayout.vue?vue&type=template&id=76d07326&"
import script from "./BaseLayout.vue?vue&type=script&lang=ts&"
export * from "./BaseLayout.vue?vue&type=script&lang=ts&"
import style0 from "./BaseLayout.vue?vue&type=style&index=0&id=76d07326&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PSubmitBar: require('/app/components/PSubmitBar.vue').default,PLoadingOverlay: require('/app/components/PLoadingOverlay.vue').default,PMainNavigation: require('/app/components/PMainNavigation.vue').default,PBreadcrumbNavigation: require('/app/components/PBreadcrumbNavigation.vue').default,PTopNavigation: require('/app/components/PTopNavigation.vue').default,PDeleteConfirmModal: require('/app/components/PDeleteConfirmModal.vue').default,PThemeProvider: require('/app/components/PThemeProvider.vue').default})
