import { render, staticRenderFns } from "./PMainNavigation.vue?vue&type=template&id=1da796a6&"
import script from "./PMainNavigation.vue?vue&type=script&lang=ts&"
export * from "./PMainNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./PMainNavigation.vue?vue&type=style&index=0&id=1da796a6&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PMainNavigationLink: require('/app/components/PMainNavigationLink.vue').default,PIcon: require('/app/components/PIcon.vue').default})
