import { Auth } from '@nuxtjs/auth-next'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { Container as InversifyContainer } from 'inversify'
import type { interfaces } from 'inversify'
// eslint-disable-next-line import/named
import { NuxtI18nInstance } from 'nuxt-i18n'
import ConfigurationApiService from '../services/ConfigurationApiService'
import ConfigurationTextApiService from '../services/ConfigurationTextApiService'
import ContactsApiService from '../services/ContactsApiService'
import DifferentiatingPermission from '../services/permissions/types/DifferentiatingPermission'
import Permission from '../services/permissions/types/Permission'
import { DataPoolPermissionsService } from '../services/permissions/DataPoolPermissionsService'
import { SYMBOLS } from './Symbols'
import CompanyApiService from '~/src/services/CompanyApiService'
import DataPoolApiService from '~/src/services/DataPoolApiService'
import DocumentsApiService from '~/src/services/DocumentsApiService'
import FileApiService from '~/src/services/FileApiService'
import { CompanyPermissionsService } from '~/src/services/permissions/CompanyPermissionsService'
import { ConfigurationPermissionsService } from '~/src/services/permissions/ConfigurationPermissionsService'
import { DocumentPermissionsService } from '~/src/services/permissions/DocumentPermissionsService'
import { PermissionService } from '~/src/services/permissions/PermissionService'
import { RegistrationPermissionsService } from '~/src/services/permissions/RegistrationPermissionsService'
import { RolePermissionsService } from '~/src/services/permissions/RolePermissionsService'
import { UserPermissionsService } from '~/src/services/permissions/UserPermissionsService'
import { NavPermissionsService } from '~/src/services/permissions/NavPermissionsService'
import PermissionsApiService from '~/src/services/PermissionsApiService'
import RegistrationApiService from '~/src/services/RegistrationApiService'
import ResetPasswordApiService from '~/src/services/ResetPasswordApiService'
import RoleApiService from '~/src/services/RoleApiService'
import { UniqueIdService } from '~/src/services/UniqueIdService'
import UserApiService from '~/src/services/UserApiService'
import { Vxm } from '~/types/portal'
import GroupApiService from '~/src/services/GroupApiService'

export class Container {
  public container: InversifyContainer

  constructor() {
    this.container = new InversifyContainer()

    this.container.bind(CompanyApiService).toSelf().inSingletonScope()
    this.container.bind(RegistrationApiService).toSelf().inSingletonScope()
    this.container.bind(UserApiService).toSelf().inSingletonScope()
    this.container.bind(FileApiService).toSelf().inSingletonScope()
    this.container.bind(RoleApiService).to(RoleApiService).inSingletonScope()
    this.container.bind(UniqueIdService).toSelf().inSingletonScope()
    this.container.bind(PermissionsApiService).toSelf().inSingletonScope()
    this.container.bind(PermissionService).toSelf().inSingletonScope()
    this.container.bind(CompanyPermissionsService).toSelf().inSingletonScope()
    this.container.bind(RolePermissionsService).toSelf().inSingletonScope()
    this.container.bind(ResetPasswordApiService).toSelf().inSingletonScope()
    this.container.bind(UserPermissionsService).toSelf().inSingletonScope()
    this.container
      .bind(ConfigurationPermissionsService)
      .toSelf()
      .inSingletonScope()
    this.container
      .bind(RegistrationPermissionsService)
      .toSelf()
      .inSingletonScope()
    this.container.bind(ConfigurationApiService).toSelf().inSingletonScope()
    this.container.bind(ContactsApiService).toSelf().inSingletonScope()
    this.container.bind(DocumentsApiService).toSelf().inSingletonScope()
    this.container.bind(DataPoolApiService).toSelf().inSingletonScope()
    this.container.bind(DocumentPermissionsService).toSelf().inSingletonScope()
    this.container.bind(ConfigurationTextApiService).toSelf().inSingletonScope()
    this.container.bind(DataPoolPermissionsService).toSelf().inSingletonScope()
    this.container.bind(GroupApiService).toSelf().inSingletonScope()

    this.container.bind(Permission).toSelf()
    this.container
      .bind<interfaces.Factory<Permission>>(SYMBOLS.PermissionFactory)
      .toFactory<Permission>(
        (context) => (mask: number, serviceId: string, groupId: number) => {
          const permission = context.container.get(Permission)
          permission.mask = mask
          permission.serviceId = serviceId
          permission.groupId = groupId

          return permission
        }
      )

    this.container.bind(DifferentiatingPermission).toSelf()
    this.container
      .bind<interfaces.Factory<DifferentiatingPermission>>(
        SYMBOLS.DifferentiatingPermissionFactory
      )
      .toFactory<DifferentiatingPermission>(
        (context) =>
          (
            mask: number,
            maskForAll: number,
            serviceId: string,
            groupId: number
          ) => {
            const permission = context.container.get(DifferentiatingPermission)
            permission.mask = mask
            permission.maskForAll = maskForAll
            permission.serviceId = serviceId
            permission.groupId = groupId

            return permission
          }
      )

    this.container.bind(NavPermissionsService).toSelf().inSingletonScope()
  }

  public setAxios(axios: NuxtAxiosInstance): void {
    this.container.bind<NuxtAxiosInstance>(SYMBOLS.Axios).toConstantValue(axios)
  }

  public setFuchsbauId(fuchsbauId: string): void {
    this.container.bind<string>(SYMBOLS.FuchsbauId).toConstantValue(fuchsbauId)
  }

  public setNavPermissionService(navPermissionService: string): void {
    this.container
      .bind<string>(SYMBOLS.NavPermissionService)
      .toConstantValue(navPermissionService)
  }

  public setAuth(auth: Auth): void {
    this.container.bind<Auth>(SYMBOLS.Auth).toConstantValue(auth)
  }

  public setTranslator(translator: NuxtI18nInstance): void {
    this.container
      .bind<NuxtI18nInstance>(SYMBOLS.Translator)
      .toConstantValue(translator)
  }

  public setVxm(vxm: Vxm): void {
    this.container.bind<Vxm>(SYMBOLS.Vxm).toConstantValue(vxm)
  }

  public companyApiService(): CompanyApiService {
    return this.container.get(CompanyApiService)
  }

  public registrationApiService(): RegistrationApiService {
    return this.container.get(RegistrationApiService)
  }

  public userApiService(): UserApiService {
    return this.container.get(UserApiService)
  }

  public fileApiService(): FileApiService {
    return this.container.get(FileApiService)
  }

  public roleApiService(): RoleApiService {
    return this.container.get(RoleApiService)
  }

  public permissionService(): PermissionService {
    return this.container.get(PermissionService)
  }

  public companyPermissionService(): CompanyPermissionsService {
    return this.container.get(CompanyPermissionsService)
  }

  public rolePermissionService(): RolePermissionsService {
    return this.container.get(RolePermissionsService)
  }

  public resetPasswordApiService(): ResetPasswordApiService {
    return this.container.get(ResetPasswordApiService)
  }

  public configurationPermissionService(): ConfigurationPermissionsService {
    return this.container.get(ConfigurationPermissionsService)
  }

  public registrationPermissionService(): RegistrationPermissionsService {
    return this.container.get(RegistrationPermissionsService)
  }

  public userPermissionService(): UserPermissionsService {
    return this.container.get(UserPermissionsService)
  }

  public permissionsApiService(): PermissionsApiService {
    return this.container.get(PermissionsApiService)
  }

  public translator(): NuxtI18nInstance {
    return this.container.get(SYMBOLS.Translator)
  }

  public contactsApiService(): ContactsApiService {
    return this.container.get(ContactsApiService)
  }

  public vxm(): Vxm {
    return this.container.get(SYMBOLS.Vxm)
  }

  public documentsApiService(): DocumentsApiService {
    return this.container.get(DocumentsApiService)
  }

  public dataPoolApiService(): DataPoolApiService {
    return this.container.get(DataPoolApiService)
  }

  public dataPoolPermissionsService(): DataPoolPermissionsService {
    return this.container.get(DataPoolPermissionsService)
  }

  public documentPermissionService(): DocumentPermissionsService {
    return this.container.get(DocumentPermissionsService)
  }

  public configurationTextApiService(): ConfigurationTextApiService {
    return this.container.get(ConfigurationTextApiService)
  }

  public groupApiService(): GroupApiService {
    return this.container.get(GroupApiService)
  }

  public auth(): Auth {
    return this.container.get(SYMBOLS.Auth)
  }

  public navPermissionService(): string {
    return this.container.get(SYMBOLS.NavPermissionService)
  }

  public setStaticNavPermissionService(serviceId: string): void {
    this.container
      .bind<string>(SYMBOLS.StaticNavPermissionService)
      .toConstantValue(serviceId)
  }

  public staticNavPermissionService(): string {
    return this.container.get(SYMBOLS.StaticNavPermissionService)
  }
}
