import getDecorators from 'inversify-inject-decorators'
import type { NuxtI18nInstance } from 'nuxt-i18n'
import { Auth } from '@nuxtjs/auth-next'
import ConfigurationApiService from '~/src/services/ConfigurationApiService'
import ContactsApiService from '~/src/services/ContactsApiService'
import { DataPoolPermissionsService } from '~/src/services/permissions/DataPoolPermissionsService'
import { Container } from '~/src/dependency_injection/Container'
import CompanyApiService from '~/src/services/CompanyApiService'
import DocumentsApiService from '~/src/services/DocumentsApiService'
import FileApiService from '~/src/services/FileApiService'
import { CompanyPermissionsService } from '~/src/services/permissions/CompanyPermissionsService'
import { ConfigurationPermissionsService } from '~/src/services/permissions/ConfigurationPermissionsService'
import { DocumentPermissionsService } from '~/src/services/permissions/DocumentPermissionsService'
import { PermissionService } from '~/src/services/permissions/PermissionService'
import { RegistrationPermissionsService } from '~/src/services/permissions/RegistrationPermissionsService'
import { RolePermissionsService } from '~/src/services/permissions/RolePermissionsService'
import { UserPermissionsService } from '~/src/services/permissions/UserPermissionsService'
import PermissionsApiService from '~/src/services/PermissionsApiService'
import RegistrationApiService from '~/src/services/RegistrationApiService'
import ResetPasswordApiService from '~/src/services/ResetPasswordApiService'
import RoleApiService from '~/src/services/RoleApiService'
import UserApiService from '~/src/services/UserApiService'
import ConfigurationTextApiService from '~/src/services/ConfigurationTextApiService'
import { Vxm } from '~/types/portal'
import DataPoolApiService from '~/src/services/DataPoolApiService'
import { NavPermissionsService } from '~/src/services/permissions/NavPermissionsService'
import GroupApiService from '~/src/services/GroupApiService'

export const container = new Container()
export const { lazyInject } = getDecorators(container.container)

export function useCompanyApiService(): CompanyApiService {
  return container.companyApiService()
}

export function useRegistrationApiService(): RegistrationApiService {
  return container.registrationApiService()
}

export function useUserApiService(): UserApiService {
  return container.userApiService()
}

export function useFileApiService(): FileApiService {
  return container.fileApiService()
}

export function useRoleApiService(): RoleApiService {
  return container.roleApiService()
}

export function useResetPasswordApiService(): ResetPasswordApiService {
  return container.resetPasswordApiService()
}

export function usePermissionService(): PermissionService {
  return container.permissionService()
}

export function useCompanyPermissionService(): CompanyPermissionsService {
  return container.companyPermissionService()
}

export function useRegistrationPermissionService(): RegistrationPermissionsService {
  return container.registrationPermissionService()
}

export function useUserPermissionService(): UserPermissionsService {
  return container.userPermissionService()
}

export function useConfigurationPermissionService(): ConfigurationPermissionsService {
  return container.configurationPermissionService()
}

export function useRolePermissionService(): RolePermissionsService {
  return container.rolePermissionService()
}

export function usePermissionsApiService(): PermissionsApiService {
  return container.permissionsApiService()
}

export function useConfigurationApiService(): ConfigurationApiService {
  return container.container.get(ConfigurationApiService)
}

export function useContactsApiService(): ContactsApiService {
  return container.container.get(ContactsApiService)
}

export function useTranslator(): NuxtI18nInstance {
  return container.translator()
}

export function useVxm(): Vxm {
  return container.vxm()
}

export function useDocumentsApiService(): DocumentsApiService {
  return container.documentsApiService()
}

export function useDataPoolApiService(): DataPoolApiService {
  return container.dataPoolApiService()
}

export function useDataPoolPermissionsService(): DataPoolPermissionsService {
  return container.dataPoolPermissionsService()
}

export function useDocumentPermissionService(): DocumentPermissionsService {
  return container.documentPermissionService()
}

export function useConfigurationTextApiService(): ConfigurationTextApiService {
  return container.configurationTextApiService()
}

export function useGroupApiService(): GroupApiService {
  return container.groupApiService()
}

export function useAuth(): Auth {
  return container.auth()
}

export function useNavPermissionService(): string {
  return container.navPermissionService()
}

export function useNavPermissionsService(): NavPermissionsService {
  return container.container.get(NavPermissionsService)
}
