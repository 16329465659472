import { useNavPermissionsService, useVxm } from '~/src/composables/dependency'
import useNavigation, { NavigationItem } from '~/src/composables/useNavigation'
import untypedBaseConfig from '~/static/base-portal-config.json'
import type {
  BasePortalConfig,
  NavigationItemConfig,
} from '~/types/portal-config'

export const basePortalConfig = untypedBaseConfig as BasePortalConfig

/**
 * Checks whether or not the currently logged in user
 * has `permission` for the given `serviceId` and `group`
 *
 * @param permission - which permission to check
 * @param serviceId - permission service ID
 * @param group - permission service group
 * @returns if user is permitted
 */
function checkModulePermissions(
  permission: number,
  serviceId: string,
  group = 1
): boolean {
  if (permission === 0) {
    return true
  }

  const permissionService = useNavPermissionsService()
  const isAllowed = permissionService
    .generateNavPermission(permission)
    .isAllowed()
  return isAllowed
}

/**
 * Check whether or not the currently logged in user may access
 * the given `navigationItem`. Uses portalConfigStore, typically
 * fetched from viewport-assets.
 *
 * @param navigationItem - Navigation item for which to check
 * @param serviceId - Navigation permission service ID
 * @param group - Navigation permission service group
 * @returns whether the logged in user is permitted to access the given navigationItem
 */
export function checkIfUserPermitted(
  navigationItem: NavigationItemConfig,
  serviceId: string,
  group = 1
): boolean {
  let permission = navigationItem.permission

  if (permission === undefined || permission === 0) {
    const target =
      typeof navigationItem.target === 'string'
        ? navigationItem.target
        : navigationItem.target.path

    if (target !== undefined) {
      const { portalConfig: portalConfigStore } = useVxm()

      const pages = portalConfigStore.allPages
      const regexMapping = portalConfigStore.regexMapping

      const found = Object.entries(pages).find(([path, { permission }]) => {
        if (permission === undefined) {
          return false
        }

        return regexMapping[path].test(target)
      })

      permission = found?.[1].permission
    }
  } else {
    permission = Math.pow(2, permission)
  }

  permission = permission ?? 0

  return checkModulePermissions(permission, serviceId, group)
}

/**
 * Generates navigation items based on the configuration
 * inside the portalConfigStore, typically fetched from
 * viewport-assets.
 *
 * @param serviceId - Navigation permission service ID
 * @returns Navigation items generated from the portal config
 */
export function makeConfigNavigationItems(serviceId: string): NavigationItem[] {
  const { makeItem } = useNavigation()
  const { portalConfig: portalConfigStore } = useVxm()

  const portalConfig = portalConfigStore.config

  if (portalConfig) {
    return portalConfig.navigation.map((navigationItem) => {
      return makeItem({
        route: navigationItem.target,
        icon: navigationItem.icon,
        activeIcon: `solid/${navigationItem.icon}`,
        label: navigationItem.label,
        userHasPermission: checkIfUserPermitted(navigationItem, serviceId),
        isExternal: navigationItem?.external === true,
        openInNewTab: navigationItem?.openInNewTab === true,
      })
    })
  }

  return []
}
