import { Context } from '@nuxt/types'
import { usePermissionService } from '~/src/composables/dependency'
import { User } from '~/schemes/auth/keycloak'

export default function ({
  redirect,
  route,
  $auth,
  store,
  $config,
}: Context): void {
  const permission =
    store.getters['portalConfig/allPages']?.[route.path]?.permission

  if (!permission) {
    return
  }

  const { NavPermissionService } = $config
  const permissionservice = usePermissionService()
  const user: User = $auth.user as User

  if (
    !permissionservice.isAllowed(
      user.company,
      permission,
      permission,
      permission,
      NavPermissionService,
      1
    )
  ) {
    redirect('/')
  }
}
