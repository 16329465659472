import { Plugin } from '@nuxt/types'

const applyConfigPlugin: Plugin = async (ctx, inject) => {
  const assetUrl = ctx.$config.assetsURL

  const hostname = location.hostname
  inject('hostname', hostname)

  let authUrl: string

  if (ctx.isDev || hostname === 'localhost') {
    // Set authUrl to keycloak.kundenportal.dev.local if in development or on localhost
    authUrl = ctx.$config.authUrl
  } else {
    // Set authUrl to auth.<hostname> if in production
    authUrl = `//auth.${hostname}`
  }

  inject('authUrl', authUrl)

  if (!ctx.isDev && hostname !== 'localhost') {
    const apiUrl = `//api.${hostname}/core`
    ctx.$config.apiUrl = apiUrl
    ctx.$axios.setBaseURL(apiUrl)
  }

  await Promise.all([
    ctx.store.dispatch('portalConfig/initialize', ctx.$config),
    ctx.store.dispatch('breadcrumb/fetchConfig', { assetUrl }),
    ctx.store.dispatch('configuration/fetchConfiguration', ctx.$config.apiUrl),
  ])
}

export default applyConfigPlugin
